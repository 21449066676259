// import logo from './logo.svg';
import './App.css';
import Navbar from './components/navbar/Navbar';
import { BrowserRouter as Router, Routes, Route, Outlet, useLocation } from "react-router-dom";
import Home from './components/home/Home';
import About from './components/about/About';
import OurTeam from './components/ourteam/OurTeam';
import { useEffect, useRef } from 'react';
import Footer from './components/footer/Footer';

function App() {
  const contactRef = useRef(null);
  const topRef = useRef(null);


  const scrollToBottom = () => {
    contactRef.current?.scrollIntoView({ behavior: "smooth" })
  }
 
  const location = useLocation();

useEffect(() => {
    topRef.current?.scrollIntoView({ behavior: "smooth" })
}, [location]);
  return (
   
      <div className='App' ref={topRef}>
        <Navbar scrollToBottom={scrollToBottom} />
        <Routes>
          <Route
            path="/"
            element={<Home />}
            compone
          ></Route>
          <Route
            path="/home"
            element={<Home />}
            compone
          ></Route>
          <Route
            path="/aboutus"
            element={<About />}
          ></Route>
          <Route
            path="/founders"
            element={<OurTeam />}
          ></Route>
        </Routes>
        <Outlet />
        <Footer contactRef={contactRef} />
      </div>
  );
}

export default App;
