import React from 'react';

const AccordianCardComponent = ({summary,cardimg}) => {
  return (
    <div className="card-body">
      <section className="section about" style={{ padding: '0' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="content-block" style={{ marginTop: '0px' }}>
                <div className="description-one">
                  <p>
                    {summary}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="image-block bg-about">
                <img
                  src={cardimg}
                  alt="Brand Strategy Concept &amp; Design"
                  className="img-responsive"
                  style={{ maxHeight: '280px', borderRadius: '0' }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AccordianCardComponent;
