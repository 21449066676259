import "./commonStyle.css";
import { Link } from "react-router-dom";
const Header = ({name}) =>{
    return( <section className="page-title bg-title overlay-dark header-container">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="title">
                <h1>{name}</h1>
              </div>
              {/* <ol className="breadcrumb p-0 m-0">
                <li className="breadcrumb-item"><Link to={"/home"} >Home</Link></li>
                <li className="breadcrumb-item">{name}</li>
              </ol> */}
            </div>
          </div>
        </div>
      </section>
)
}
export default Header;