import React from 'react'
import Mission from "../../assets/gallery/Mission.png";
import Vision from "../../assets/gallery/Vission.jpg";
import CustomizedAccordions from '../../common-component/CustomizedAccordions';
import Header from '../../common-component/Header';
import Image2 from '../../assets/gallery/Image_2.jpg';
import Image3 from '../../assets/gallery/Image_3.jpg';
import Image4 from '../../assets/gallery/Image_4.jpg';
import Image5 from '../../assets/gallery/Image_5.jpeg';
import Image6 from '../../assets/gallery/Image_6.jpg';
import Image7 from '../../assets/gallery/Image_7.jpg';



const About = () => {
  const data = [
    {title:"Brand Strategy Concept & Design",summary:'Our Brand Strategy Concept & Design service crafts compelling brand narratives that resonate with your audience. We meticulously analyze market trends and consumer behavior to develop strategic concepts that elevate your brand identity. From logo design to comprehensive brand guidelines, we ensure every element aligns with your vision. Our goal is to create a cohesive and impactful brand presence that stands out in the marketplace.',img:Image2},
    {title:"Exhibit Designs & Fabrication",summary:'Our Exhibit Designs & Fabrication service transforms ideas into immersive experiences. We specialise in creating visually stunning and functional exhibits that engage audiences. From conceptualization to construction, our team handles every detail to bring your vision to life. Utilising cutting-edge materials and techniques, we deliver exhibits that not only look impressive but also enhance visitor interaction and engagement.',img:Image3},
   {title:"Brand Films, TVCs, AVs, Event Coverage & Animation",summary:`We produce high-quality Brand Films, TVCs, AVs, Event Coverage, and Animations that tell your brand's story with creativity and precision. Our team of experts handle every aspect of production, from scripting and shooting to post-production and editing. We use state-of-the-art technology and innovative techniques to create content that captures attention and leaves a lasting impression. Our goal is to deliver visually compelling and emotionally engaging media that amplifies your brand message.`,img:Image4},
    {title:"Event Production & Technological Engagement",summary:'Our Event Production & Technological Engagement service combines creativity with cutting-edge technology to produce unforgettable events. From large-scale conferences to intimate gatherings, we handle all aspects of event planning and execution. We integrate advanced technological solutions, such as virtual reality and interactive displays, to enhance audience experience and engagement. Our meticulous planning and innovative approach ensures the event is a resounding success.',img:Image5},
    {title:"R&R and MICE Experiences",summary:'We specialize in creating unique R&R (Rewards and Recognition) and MICE (Meetings, Incentives, Conferences, and Exhibitions) experiences that motivate and inspire. Our customized programs are designed to recognize achievements, foster team building, and drive business growth. We handle everything from venue selection and logistics to entertainment and activities, ensuring a seamless and memorable experience. Our attention to detail and creative flair make each event distinctive and impactful.',img:Image6},
   {title:"Celebrity & Talent Management",summary:`We offer comprehensive Celebrity & Talent Management services, connecting you with the perfect influencers and celebrities to elevate your brand. Our team manages everything from negotiations to logistics, ensuring a seamless partnership. Whether for endorsements, appearances, or social media campaigns, we leverage our extensive network to match your brand with the right talent. Our approach maximizes visibility and engagement, driving your brand's success.`,img:Image7},
    
  
  ]
  return (<>
  <div>
     <Header name="About Us" />
      <div className="container-fluid feature overflow-hidden py-5" style={{ backgroundColor: '#f8f9fa' }}>
        <div className="container py-5">
          <div className="text-center mx-auto mb-5" style={{ maxWidth: '900px' }}>
            {/* <h2 className="display-4" style={{ color: '#fa0202', fontWeight: 'bold' }}>About RedCap</h2> */}
            <p className="lead" style={{ color: '#343a40', fontSize: '1.2rem',textAlign:'left' }}>
            RedCap Events & Communications is the epicentre of creativity and innovation in the events and branding landscape. Committed to turning ideas into vivid realities, we craft unforgettable experiences that captivate and inspire. Beyond merely offering services, we create immersive experiences enriched by cutting-edge digital technologies. Let’s create something extraordinary together.
            </p>
          </div>
        </div>
      </div>

      <section className="section about" style={{ padding: 0 }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 align-self-center">
              <div className="image-block bg-about">
                <img src= {Mission} alt="Our Mission" className="img-responsive" style={{ maxHeight: '280px', borderRadius: 0 }} />
              </div>
            </div>
            <div className="col-lg-8 col-md-6 align-self-center">
              <div className="content-block">
                <h3>Our Mission</h3>
                <div className="description-one">
                  <p>
                    "Our mission is to be the catalysts of extraordinary events and captivating films, driven by creativity, precision, and client satisfaction, ensuring each occasion is a masterpiece of unforgettable memories."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section about" style={{ padding: 0 }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-6 align-self-center">
              <div className="content-block">
                <h3>Our Vision</h3>
                <div className="description-one">
                  <p>"RedCap Events & Communications envisions crafting unforgettable experiences through seamless event management and innovative filmmaking, leaving an enduring mark on every moment we create."</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 align-self-center">
              <div className="image-block bg-about">
                <img src={Vision} alt="Our Vision" className="img-responsive" style={{ maxHeight: '280px', maxWidth: '300px', borderRadius: 0 }} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section className="aboutsec-2 expertise-section">
    <div class="container">
        <div class="custom-card">
        <div class="col-12">
					  <div class="fh-section-title clearfix text-left version-dark paddbtm30">
						<h2>Our Expertise</h2>
					  </div>
    <CustomizedAccordions data={data}/>
    </div>
    </div>
    </div>
    </section>
    {/* <section className="aboutsec-2 expertise-section">
    <div class="container">
        <div class="custom-card">
				
				  
					<div class="col-12">
					  <div class="fh-section-title clearfix text-left version-dark paddbtm30">
						<h2>Our Expertise</h2>
					  </div>
					  <div class="accordion" id="accordionExample">
						<div class="card">
						  <div class="card-header" id="headingOne">
							<h5 class="mb-0">
							  <a class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
								Brand Strategy Concept & Design
							  </a>
							</h5>
						  </div>
						  <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
							<div class="card-body">
                <section class="section about" style={{padding: 0}}>
                  <div class="container">
                    <div class="row">
                      
                      <div class="col-lg-6 col-md-6 align-self-center">
                        <div class="content-block" style={{marginTop:'0px'}}>
                          <div class="description-one">
                            <p>Our Brand Strategy Concept & Design service crafts compelling brand narratives that resonate with your audience. We meticulously analyze market trends and consumer behavior to develop strategic concepts that elevate your brand identity. From logo design to comprehensive brand guidelines, we ensure every element aligns with your vision. Our goal is to create a cohesive and impactful brand presence that stands out in the marketplace.</p>
                          </div>
                          
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 align-self-center">
                        <div class="image-block bg-about">
                          <img src="images/gallery/Image_2.jpg" alt="Brand Strategy Concept & Design" class="img-responsive" style={{maxHeight: '280px', borderRadius: 0}}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

							</div>
						  </div>
						</div>
						<div class="card">
						  <div class="card-header" id="headingTwo">
							<h5 class="mb-0">
							  <a class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
								Celebrity & Talent Management
							  </a>
							</h5>
						  </div>
						  <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
							<div class="card-body">
                <section class="section about" style={{padding: 0}}>
                  <div class="container">
                    <div class="row">
                      
                      <div class="col-lg-6 col-md-6 align-self-center">
                        <div class="content-block" style={{marginTop:'0px'}}>
                          <div class="description-one">
                            <p>We offer comprehensive Celebrity & Talent Management services, connecting you with the perfect influencers and celebrities to elevate your brand. Our team manages everything from negotiations to logistics, ensuring a seamless partnership. Whether for endorsements, appearances, or social media campaigns, we leverage our extensive network to match your brand with the right talent. Our approach maximizes visibility and engagement, driving your brand's success.</p>
                          </div>
                          
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 align-self-center">
                        <div class="image-block bg-about">
                          <img src="images/gallery/Image_3.jpg" alt="Celebrity & Talent Management" class="img-responsive" style={{maxHeight: '280px', borderRadius:'0'}}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
							</div>
						  </div>
						</div>
						<div class="card">
						  <div class="card-header" id="headingThree">
							<h5 class="mb-0">
							  <a class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
								Exhibit Designs & Fabrication
							  </a>
							</h5>
						  </div>
						  <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
							<div class="card-body">
                <section class="section about" style={{padding:0}}>
                  <div class="container">
                    <div class="row">
                      
                      <div class="col-lg-6 col-md-6 align-self-center">
                        <div class="content-block" style={{marginTop:'0px'}}>
                          <div class="description-one">
                            <p>Our Exhibit Designs & Fabrication service transforms ideas into immersive experiences. We specialize in creating visually stunning and functional exhibits that captivate audiences. From conceptualization to construction, our team handles every detail to bring your vision to life. Utilizing cutting-edge materials and techniques, we deliver exhibits that not only look impressive but also enhance visitor interaction and engagement.</p>
                          </div>
                          
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 align-self-center">
                        <div class="image-block bg-about">
                          <img src="images/gallery/Image_4.jpg" alt="Exhibit Designs & Fabrication" class="img-responsive" style={{maxHeight: '280px', borderRadius: 0}}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
							</div>
						  </div>
						</div>
						<div class="card">
						  <div class="card-header" id="headingFour">
							<h5 class="mb-0">
							  <a class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
								Brand Films, TVCs, AVs, Event Coverage & Animation
							  </a>
							</h5>
						  </div>
						  <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
							<div class="card-body">
                <section class="section about" style={{padding:0}}>
                  <div class="container">
                    <div class="row">
                      
                      <div class="col-lg-6 col-md-6 align-self-center">
                        <div class="content-block" style={{marginTop:'0px'}}>
                          <div class="description-one">
                            <p>We produce high-quality Brand Films, TVCs, AVs, Event Coverage, and Animations that tell your brand's story with creativity and precision. Our team of experts handles every aspect of production, from scripting and shooting to post-production and editing. We use state-of-the-art technology and innovative techniques to create content that captures attention and leaves a lasting impression. Our goal is to deliver visually compelling and emotionally engaging media that amplifies your brand message.</p>
                          </div>
                          
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 align-self-center">
                        <div class="image-block bg-about">
                          <img src="images/gallery/Image_5.jpeg" alt="Brand Films, TVCs, AVs, Event Coverage & Animation" class="img-responsive" style={{maxHeight: '280px', borderRadius: 0}}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
							</div>
						  </div>
						</div>
						<div class="card">
						  <div class="card-header" id="headingFive">
							<h5 class="mb-0">
							  <a class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
								Event Production & Technological Engagement
							  </a>
							</h5>
						  </div>
						  <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
							<div class="card-body ">

                <section class="section about" style={{padding:0}}>
                  <div class="container">
                    <div class="row">
                      
                      <div class="col-lg-6 col-md-6 align-self-center">
                        <div class="content-block" style={{marginTop:'0px'}}>
                          <div class="description-one">
                            <p>Our Event Production & Technological Engagement service combines creativity with cutting-edge technology to produce unforgettable events. From large-scale conferences to intimate gatherings, we handle all aspects of event planning and execution. We integrate advanced technological solutions, such as virtual reality and interactive displays, to enhance attendee experience and engagement. Our meticulous planning and innovative approach ensure your event is a resounding success.</p>
                          </div>
                          
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 align-self-center">
                        <div class="image-block bg-about">
                          <img src="images/gallery/Image_6.jpg" alt="Event Production & Technological Engagement" class="img-responsive" style={{maxHeight: '280px', borderRadius: 0}}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
							</div>
						  </div>
						</div>
						<div class="card">
						  <div class="card-header" id="headingSix">
							<h5 class="mb-0">
							  <a class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
								R&R and MICE Experiences
							  </a>
							</h5>
						  </div>
						  <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
							<div class="card-body">
                <section class="section about" style={{padding:0}}>
                  <div class="container">
                    <div class="row">
                      
                      <div class="col-lg-6 col-md-6 align-self-center">
                        <div class="content-block" style={{marginTop:'0px'}}>
                          <div class="description-one">
                            <p>We specialize in creating unique R&R (Rewards and Recognition) and MICE (Meetings, Incentives, Conferences, and Exhibitions) experiences that motivate and inspire. Our customized programs are designed to recognize achievements, foster team building, and drive business growth. We handle everything from venue selection and logistics to entertainment and activities, ensuring a seamless and memorable experience. Our attention to detail and creative flair make each event distinctive and impactful.</p>
                          </div>
                          
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 align-self-center">
                        <div class="image-block bg-about">
                          <img src="images/gallery/Image_7.jpg" alt="R&R and MICE Experiences" class="img-responsive" style={{maxHeight: '280px', borderRadius:'0'}}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
									  </div>
									</div>
								  </div>
							  </div>
						</div>
					</div>
				
        
    </div>
    </section>
    <footer className="subfooter">
      <div className="container">
        <div className="row">
          <div className="col-md-6 align-self-center">
            <div className="copyright-text">
              <p className="white-text">
                <a href="#">REDCAP</a> &#169; 2024 All Rights Reserved
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <a href="#" className="to-top">
              <i className="fa fa-angle-up"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
     */}
  </>
  );
};
  


export default About;
