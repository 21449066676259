import React from 'react';
import video from "../../assets/Video1.mp4";
const Home = () => {
  return (
    <section className="banner">
      <div className="video-container">
        <video autoPlay muted loop className="background-video">
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </section>
    
  );
};

export default Home;
