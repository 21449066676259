import React from 'react';
import ClientsBanner from '../../common-component/ClientsBanner';

const Footer = ({contactRef}) => {
  
  return (
    <>
    <ClientsBanner />
      <footer className="footer-main" id="footer" ref={contactRef}>
        <div className="container">
          <h2 className="fh-section-title text-center">Contact Details</h2>
          <div className="row">
            <div className="col-md-4 fh-contact-box">
              <i className="fas fa-map-marker-alt"></i>
              <h4 className="box-title">Meet us</h4>
              <div className="desc">
                <p style={{ color: 'white' }}>
                  Office No 2004, Plan S Business Park, D-108/1, opp. DY Patil Stadium, MIDC Industrial Area, Nerul, Navi Mumbai, Maharashtra 400706
                </p>
              </div>
            </div>
            <div className="col-md-4 fh-contact-box">
              <i className="fas fa-envelope"></i>
              <h4 className="box-title">Mail us at</h4>
              <div className="desc">
                <a href="mailto:yogesh@redcapevents.in" style={{ color: 'white' }}>yogesh@redcapevents.in</a><br />
                <a href="mailto:ssamridh@redcapevents.in" style={{ color: 'white' }}>ssamridh@redcapevents.in</a>
                
              </div>
            </div>
            <div className="col-md-4 fh-contact-box">
              <i className="fas fa-phone-alt"></i>
              <h4 className="box-title">Call us on</h4>
              <div className="desc">
                <a href="tel:+91 9664585914" style={{ color: 'white' }}>Mob: +91 9664585914</a><br />
                <a href="tel:+91 7842227368" style={{ color: 'white' }}>Mob: +91 9769347059</a>
                
              </div>
            </div>
          </div>
        </div>
      </footer>

      <footer className="subfooter">
        <div className="container">
          <div className="row">
            <div className="col-md-11 align-self-center">
              <div className="copyright-text">
                <p className="white-text">
                  <a href="#">RedCap</a> &#169; 2024 All Right Reserved
                </p>
              </div>
            </div>
            <div className="col-md-1">
              <a href="#" className="to-top">
                <i className="fa fa-angle-up"></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
