import React from 'react';
import ClientBox from './ClientBox'; // Adjust the import path as needed
import sbiLogo from '../assets/Square shape logo/3 SBI.png';
import shopersStopLogo from '../assets/Square shape logo/2 Shoppers Stop.png';
import coffeLogo from '../assets/Square shape logo/4 Coffee Board.png';
import Crayons from '../assets/Square shape logo/5 Crayons.png';
import borzo_Logo from '../assets/Square shape logo/1 Borzo.png';
import artha from '../assets/Square shape logo/6 Artha.png';






const clientsData = [
  { imgSrc: borzo_Logo, altText: 'Borzo', clientName: 'Borzo', fontSize: '15px' },
  { imgSrc: shopersStopLogo, altText: 'Shoppers Stop', clientName: 'Shoppers Stop', fontSize: '14px' },
  { imgSrc: sbiLogo, altText: 'SBI', clientName: 'SBI', fontSize: '14px' },
  { imgSrc: coffeLogo, altText: 'Coffee Board of India', clientName: 'Coffee Board of India', fontSize: '14px' },
  { imgSrc: Crayons, altText: 'Crayons', clientName: 'Crayons', fontSize: '15px' },
  { imgSrc: artha, altText: 'Artha animation', clientName: 'Artha animation', fontSize: '13.5px' }
];

const ClientsBanner = () => {
  return (
    <section  style={{marginTop:"1px"}}className="banner" >
      <div className="container" >
        <div className="row">
          <div className="col-md-12 text-center">
            <h2>Our Clients</h2>
          </div>
        </div>
        <div className="row clients-slider text-center">
          {clientsData.map((client, index) => (
            <ClientBox
              key={index}
              imgSrc={client.imgSrc}
              altText={client.altText}
              clientName={client.clientName}
              fontSize={client.fontSize}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ClientsBanner;
