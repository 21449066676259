import React from 'react';
import speaker1 from '../../assets/speakers/Image 9.jpg';
import speaker2 from '../../assets/speakers/Image 8.jpg';
import Header from '../../common-component/Header';

const SpeakerCard = ({ image, name, profession, details }) => {
  return (<>
     {/*  */}

    <div className="custom-card">
      <div className="row">
        <div className="col-lg-5 col-md-6 align-self-md-center">
          <div className="image-block">
            <img src={image} className="img-fluid" alt="speaker" />
          </div>
        </div>
        <div className="col-lg-7 col-md-6 align-self-center">
          <div className="content-block">
            <div className="name">
              <h3>{name}</h3>
            </div>
            <div className="profession">
              <p className="red-text">{profession}</p>
            </div>
            <div className="details" style={{ textAlign: 'left' }}>
              {details.map((detail, index) => (
                <p key={index}>{detail}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

const OurTeams = () => {
  return (
    <div>
<Header name="Founders" />
      <section className="aboutsec-2">
        <div className="container">
          <SpeakerCard
            image={speaker1}
            name="Yogesh Dhawale"
            profession="Co-Founder, RedCap Events & Communications"
            details={[
              "With over 15 years of trailblazing excellence in event management, Yogesh stands as a beacon in the industry. Unwavering in the face of challenges, his work exemplifies a blend of productivity and creativity, honed to perfection over time. As the co-founder of RedCap Events & Communications, Yogesh has skillfully guided the agency to new heights, setting a gold standard for what a perfect event should be.",
              "His expertise and visionary leadership continue to shape unforgettable experiences."
            ]}
          />
        </div>
      </section>
      <section className="aboutsec-2">
        <div className="container">
          <SpeakerCard
            image={speaker2}
            name="Ssamridh S Aggarwal"
            profession="Co-Founder, RedCap Events & Communications"
            details={[
              "Ssamridh S Aggarwal, the visionary Co-Founder of RedCap Events & Communications, has been shaping the landscape of corporate events in India since 2017. With a rich background as an event manager, Ssamridh brings a wealth of experience and an unwavering passion for crafting unforgettable experiences. His dedication to excellence ensures that every project he undertakes leaves a lasting impression on clients and audience alike.",
              "As a hardworking young entrepreneur, Ssamridh is committed to redefining event experiences with creativity, dedication, and flair. Join Ssamridh on his inspiring journey to elevate the standards of event management and deliver unparalleled event experiences."
            ]}
          />
        </div>
      </section>
    </div>
  );
};

export default OurTeams;
