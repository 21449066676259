import React from 'react';

const ClientBox = ({ imgSrc, altText, clientName, fontSize }) => {
  return (
    <div className="col-lg-2 col-md-2 col-sm-4 col-xs-4 fh-icon-box style-2 version-dark icon-center">
      <img src={imgSrc} alt={altText} />
      <h4 className="box-title" style={{ fontSize: fontSize }}><span>{clientName}</span></h4>
    </div>
  );
};

export default ClientBox;
