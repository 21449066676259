import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import companyLogo from '../../assets/logo.png'
import './Navbar.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
  useLocation,
  useNavigate
} from "react-router-dom";

// Import useLocation hook
const drawerWidth = 240;
const navItems = ['Home', 'About Us', 'Founders', 'Contact'];
function NavBar(props) {
  const { window,handleClickOpen,scrollToBottom } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate()
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <NavLink  to={`/home`}><img src={companyLogo} style={{
        width: '100%',
        padding: 'f'
      }}/></NavLink>
      
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              {item.toLocaleLowerCase()=== 'contact' ? <button className="contact-btn" onClick={scrollToBottom}>{item}</button>:<NavLink   className={({ isActive, isPending }) =>
    isPending ? "pending" : isActive ? "active" : ""
  } to={`/${item.replace(" ","").toLocaleLowerCase()}`}>{item}</NavLink>}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    // <nav className="navbar main-nav border-less fixed-top navbar-expand-lg p-0">
    //   <div className="container-fluid p-0">
    //     {/* logo */}
    //     <a className="navbar-brand" href="index.html">
    //       <img className="logo-img" src={companyLogo} alt="logo" />
    //     </a>
    //     <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
    //       aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    //       <span className="fa fa-bars"></span>
    //     </button>
    //     <div className="collapse navbar-collapse" id="navbarNav">
    //       <ul className="navbar-nav mx-auto">
    //         <li className="nav-item">
           
    //           <NavLink className={"nav-link nav-link-bold" + (url === "/" ?" active" : "")} to={`/home`}>Home</NavLink>
    //         </li>
    //         <li className="nav-item">
    //           <a className="nav-link nav-link-bold" href="about-us.html">About us</a>
    //         </li>
    //         <li className="nav-item active">
    //           <a className="nav-link nav-link-bold" href="our-team.html">Founders</a>
    //         </li>
    //         <li className="nav-item">
    //           <a className="nav-link nav-link-bold" href="#footer" id="contactLink">Contact</a>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>
    // </nav>
    <Box sx={{ display: 'flex', height:'69px' }}>
      <CssBaseline />
      <AppBar component="nav" >
        <div className='custom-nav'>
        <Toolbar>
          <IconButton
          disableFocusRipple={false}
          disableRipple={false}
            color="inherit"
            aria-label="open drawer"
            edge="start"
            // onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
           <IconButton disableFocusRipple={false}
          disableRipple={false} sx={{ display: {  md: 'block' } }}  onClick={handleDrawerToggle}><i class="fa fa-bars"></i></IconButton> <NavLink  to={`/home`}><img src={companyLogo} /></NavLink> 
          </IconButton>
          <IconButton disableFocusRipple={false} onClick={()=>{
navigate('/home')
          }}
          disableRipple={false} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}> <img src={companyLogo} /> </IconButton>
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            {navItems.map((item) => (
              <Button className="custom-nav-items" key={item} sx={{ color: '#fff' }}>
              {item.toLocaleLowerCase()=== 'contact' ? <button className="contact-btn" onClick={scrollToBottom}>{item}</button>:<NavLink  className={({ isActive, isPending }) =>
    isPending ? "pending" : isActive ? "active" : ""
  } to={`/${item.replace(" ","").toLocaleLowerCase()}`}>{item}</NavLink>}

               {/* <NavLink to={`/${item.replace(" ","").toLocaleLowerCase()}`}>{item}</NavLink> */}
              </Button>
            ))}
          </Box>
         
        </Toolbar>
        </div>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}

NavBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default NavBar;
